import "@fontsource/poppins";
import "@fontsource/space-grotesk/variable.css";
import * as React from "react";
import { GlobalStyle } from "./src/components/layout/GlobalStyles/GlobalStyles";
import { App } from "./src/components/molecules/App/App";
// Fontsource font-variants: https://fontsource.org/docs/variable-fonts

export const wrapPageElement = ({ element, props }) => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <App {...props}>{element}</App>
        </React.Fragment>
    );
};
